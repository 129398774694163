import { useAuthStore } from "@/stores/auth";

export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    console.log("middleware shopper to:", to);
    const auth = useAuthStore(); // make authenticated state reactive
    if (!auth.loggedIn) {
      console.warn("Silahkan login:");
      return navigateTo("/login", { replace: true });
    } else {
      return true;
    }
  }
});
